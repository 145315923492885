<template>
  <div class="mini-tool-page padding layout-horizontal font-align-left">
    <div class="card layout-inflexible" style="overflow: hidden;" v-if="false">
      <div style="background-color: #f091a6; color: white;"
           class="padding-top-large padding-horizontal-large padding-bottom">
        <user-info :user-id="userId" :organization-id="organizationId"></user-info>
        <div v-if="gravidaWeightParams" class="margin-top">
          <div v-if="gravidaWeightParams.gravidaAge" class="font-size-large margin-bottom-small">
            <span>第{{gravidaWeightParams.gravidaAge.weeks + 1}}周</span>
            <span>+{{gravidaWeightParams.gravidaAge.days}}天</span>
            <span v-if="gravidaWeightParams.fetusesStr"> · {{gravidaWeightParams.fetusesStr}}</span>
          </div>
          <div v-if="gravidaWeightParams.expectedDateOfChildBirthStr">预产期：{{gravidaWeightParams.expectedDateOfChildBirthStr}}</div>
          <div v-if="gravidaWeightParams.lowerLimitStr && gravidaWeightParams.upperLimitStr">
            当前孕周正常体重范围：
            <span>{{gravidaWeightParams.lowerLimitStr}}</span>
            <span> - </span>
            <span>{{gravidaWeightParams.upperLimitStr}}kg</span>
          </div>
          <div v-if="gravidaWeightParams.gravidaOriginalWeightStr">产前体重：{{gravidaWeightParams.gravidaOriginalWeightStr}}kg</div>
          <div v-if="gravidaWeightParams.gravidaOriginalBmiStr">产前BMI：{{gravidaWeightParams.gravidaOriginalBmiStr}}</div>
        </div>
      </div>
      <gravida-weight-calendar :user-id="userId" :organization-id="organizationId"
                               class="margin-horizontal-small margin-bottom"></gravida-weight-calendar>
    </div>
    <div class="layout-flexible margin-left layout-vertical">
      <div class="font-size-large font-weight-bold">孕期体重</div>
      <div class="card margin-top">
        <div class="padding-top-large padding-horizontal-large padding layout-horizontal layout-middle">
          <div class="font-weight-bold layout-inflexible"><i class="fas fa-line-chart"></i> 趋势图</div>
          <div class="layout-flexible"></div>
          <div class="btn-group layout-inflexible">
            <div
                class="btn btn-mode"
                :class="{'active': chartMode === GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK}"
                @click="handleChartModeClick(GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK)"
            >按孕周显示
            </div>
            <div
                class="btn btn-mode"
                :class="{'active': chartMode === GravidaWeightChartMode.MODE_IN_DAYS}"
                @click="handleChartModeClick(GravidaWeightChartMode.MODE_IN_DAYS)"
            >按天显示
            </div>
          </div>
        </div>
        <div class="padding-bottom-large">
          <gravida-weight-linear-chart
              class="chart"
              :user-id="userId"
              :organization-id="organizationId"
              :mode="chartMode"
          ></gravida-weight-linear-chart>
        </div>
      </div>
      <div class="card layout-flexible layout-vertical margin-top" style="overflow: hidden">
        <div class="font-weight-bold padding-horizontal-large padding-top-large padding">
          <i class="fas fa-list"></i> 列表
        </div>
        <gravida-weight-list
            :user-id="userId"
            :organization-id="organizationId"
        ></gravida-weight-list>
      </div>

    </div>
  </div>
</template>

<script>
import UserInfo from "@/pages/minitools/components/UserInfo";
import GravidaWeightCalendar from "@/pages/minitools/gravida_weight/components/GravidaWeightCalendar";
import GravidaWeightLinearChart from "@/pages/minitools/gravida_weight/components/GravidaWeightLinearChart";
import {GravidaWeightChartMode} from "@/pages/minitools/gravida_weight/components/GravidaWeightChartMode";
import GravidaWeightList from "@/pages/minitools/gravida_weight/components/GravidaWeightList";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";

export default {
  name: "MiniToolGravidaWeight",
  mixins: [httpapi],
  components: {GravidaWeightList, GravidaWeightLinearChart, GravidaWeightCalendar, UserInfo},
  data() {
    return {

      /**
       * 常量
       */
      GravidaWeightChartMode,

      /**
       * 输入参数
       */
      userId: null,
      organizationId: null,

      /**
       * 趋势图模式
       */
      chartMode: GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK,

      /**
       * 当前孕期体重参数
       */
      gravidaWeightParams: null,

    }
  },
  mounted() {
    let query = this.$route.query;
    this.loadingCode++;
    this.userId = query.userId;
    this.organizationId = query.organizationId;
    this.loadGravidaWeightParams();
  },
  methods: {
    handleChartModeClick: function (mode) {
      this.chartMode = mode;
    },

    loadGravidaWeightParams: function () {
      this.$reqGet({
        path: '/mt/gravida/weight/params/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
        }
      }).then(res => {
        let weightParams = res.data;
        weightParams.expectedDateOfChildBirthStr = TimeUtils.format('yyyy-MM-dd', weightParams.expectedDateOfChildBirth);
        weightParams.gravidaOriginalWeightStr = ExactNumber.stringify(weightParams.gravidaOriginalWeight);
        weightParams.gravidaOriginalBmiStr = ExactNumber.stringify(weightParams.gravidaOriginalBmi);
        weightParams.lowerLimitStr = ExactNumber.stringify(weightParams.lowerLimit);
        weightParams.upperLimitStr = ExactNumber.stringify(weightParams.upperLimit);
        weightParams.targetWeightGainLowerStr = ExactNumber.stringify(weightParams.targetWeightGainLower);
        weightParams.targetWeightGainUpperStr = ExactNumber.stringify(weightParams.targetWeightGainUpper);
        if (weightParams.fetuses) {
          let fetusesStr;
          switch (weightParams.fetuses) {
            case 2:
              fetusesStr = "双胞胎";
              break;
            case 3:
              fetusesStr = "三胞胎";
              break;
            default:
              if (weightParams.fetuses > 3) {
                fetusesStr = `${weightParams.fetuses}胞胎`;
              } else {
                fetusesStr = ""
              }
          }
          weightParams.fetusesStr = fetusesStr;
        }
        this.gravidaWeightParams = weightParams;
      })
    }
  }
}
</script>

<style scoped>

.mini-tool-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: whitesmoke;
}

.btn-group {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.btn-group > .btn {
  text-align: center;
  padding: 4px 16px;
  border: 1px solid #f091a6;
  border-left: none;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
}

.btn-group > .btn:hover {
  opacity: .7;
}

.btn-group > .btn.active {
  background-color: #f091a6;
  color: white;
}

.btn-group > .btn:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-left: 1px solid #f091a6;
}

.btn-group > .btn:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.chart {
  height: 200px;
}

</style>