/**
 * 孕期体重水平
 */
export const GravidaWeightLevel = {

    /**
     * 未知
     */
    UNKNOWN: 0,

    /**
     * 正常
     */
    NORMAL: 1,

    /**
     * 偏重
     */
    OVER_WEIGHT: 2,

    /**
     * 偏轻
     */
    UNDER_WEIGHT: 3,
}