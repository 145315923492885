export class GravidaWeekChartConverter {

    PFEFIX = "w_";

    constructor(skip = 0) {
        this.skip = skip;
    }

    convertKey2Index(key) {
        let week = parseInt(key.substring(this.PFEFIX.length));
        return week;
    }

    convertIndex2Key(index) {
        return `${this.PFEFIX}${index}`;
    }

    formatLabel(key, index, skip = false) {
        return `第${index + 1}周`;
    }

}