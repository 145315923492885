<template>
  <calendar
      :date-mark-definitions="dateMarkDefinitions"
      :date-marks="dateMarks"
      @changecurrent="handleMouseChange"
  >
  </calendar>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import {GravidaWeightLevel} from "@/assets/javascript/gravida-weight-utils";

export default {
  name: "GravidaWeightCalendar",
  mixins: [httpapi],
  components: {Calendar},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {

      /**
       * 按月份存储的孕期体重数据
       */
      pregnancyWeightMonthMap: {},

      /**
       * 预定义日期标记
       */
      dateMarkDefinitions: [
        /**
         * 体重正常
         */
        {
          backgroundColor: '#faf4f5',
        },
        /**
         * 体重偏低
         */
        {
          backgroundColor: '#d2e3f1',
        },
        /**
         * 体重偏高
         */
        {
          backgroundColor: '#e3c7ee',
        }
      ],

      /**
       * 日期标记
       */
      dateMarks: {},

      /**
       * 正在加载的月份
       */
      loadingMonths: {},
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    },
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.loadingMonths = {};
        this.pregnancyWeightMonthMap = {};
        this.loadMonth(new Date());
      },
      immediate: true,
    }
  },
  methods: {

    handleMouseChange: function (e) {
      this.loadMonth(e.month);
    },

    notifyDateChange: function (date) {
      if (date) {
        this.loadMonth(date);
      }
    },

    updateDateMarkMap: function () {
      let dateMarks = new Object();

      let pregnancyWeightMonthMap = this.pregnancyWeightMonthMap;
      for (let monthStart in pregnancyWeightMonthMap) {
        let monthWeightList = pregnancyWeightMonthMap[monthStart];
        if (!monthWeightList) continue;

        for (let dateNode of monthWeightList) {
          let markIndex = null;
          switch (dateNode.level) {
            case GravidaWeightLevel.NORMAL:
              markIndex = 0;
              break;
            case GravidaWeightLevel.UNDER_WEIGHT:
              markIndex = 1;
              break;
            case GravidaWeightLevel.OVER_WEIGHT:
              markIndex = 2;
              break;
          }
          if (markIndex !== null) {
            dateMarks[dateNode.date] = markIndex;
          }
        }
      }


      this.dateMarks = dateMarks;
    },

    loadMonth: function (month) {
      if (!this.userId || !this.organizationId) return;
      let monthStart = TimeUtils.format('yyyy-MM-dd', TimeUtils.getMonthStart(month));
      let isMonthLoading = this.loadingMonths[monthStart];
      if (isMonthLoading) return;

      this.loadingMonths[monthStart] = true;

      this.$reqGet({
        path: '/mt/gravida/weight/list/by_month',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          dateInMonth: monthStart,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let pregnancyWeightMonthMap = this.pregnancyWeightMonthMap;
            pregnancyWeightMonthMap[monthStart] = res.data;
            this.updateDateMarkMap();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            delete this.loadingMonths[monthStart];
          });
    }
  }
}
</script>

<style scoped>

</style>