<template>
  <linear-chart
      :serials="chartSerials"
      :regions="chartStandardRegions"
      @scrolltoleft="handleScrollToLeft"
      @scrolltoright="handleScrollToRight"
  ></linear-chart>
</template>

<script>
import LinearChart from "@/components/chart/LinearChart";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import {GravidaWeightLevel} from "@/assets/javascript/gravida-weight-utils";
import {DateChartConverter} from "@/components/chart/DateChartConverter";
import {GravidaWeekChartConverter} from "@/pages/minitools/base/GravidaWeekChartConverter";
import {GravidaWeightChartMode} from "@/pages/minitools/gravida_weight/components/GravidaWeightChartMode";

export default {
  name: "GravidaWeightLinearChart",
  mixins: [httpapi],
  components: {LinearChart},
  props: {
    userId: Number,
    organizationId: Number,
    mode: {}
  },
  data() {
    return {
      /**
       * api同步参数
       */
      loadingCode: 1,
      busyLoadingCode: 0,

      /**
       * 孕期体重日列表
       */
      pregnancyWeightDateList: [],

      /**
       * 孕期体重周列表
       */
      pregnancyWeightWeekList: [],

      /**
       * 孕期体重标准
       */
      standardList: [],

      /**
       * 曲线图
       */
      chartSerials: [],
      chartGrid: null,
      chartStandardRegions: [],

      weekOfNotifyDateChange: null,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId, mode} = this;
      return {
        userId,
        organizationId,
        mode,
      };
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.loadGravidaWeightListBefore();
        this.loadGravidaWeightStandardList();
      },
      immediate: true,
    },
    mode: {
      handler() {
        this.loadingCode ++;
        this.pregnancyWeightWeekList = [];
        this.pregnancyWeightDateList = [];
        this.loadGravidaWeightListBefore();
        this.loadGravidaWeightStandardList();
        this.updateStandardRegion();
      },
      immediate: true,
    }
  },

  methods: {

    handleScrollToLeft: function () {
      this.loadGravidaWeightListBefore();
    },

    handleScrollToRight: function () {
      this.loadGravidaWeightListAfter();
    },

    updateChartSerials: function () {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.updateChartSerialsModeInDays();
          this.updateStandardRegionModeInDays();
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.updateChartSerialModeInWeeks();
          this.updateStandardRegionModeInWeeks();
          break;
      }
    },

    updateChartSerialsModeInDays: function () {
      let serial = {
        color: '#f091a6',
        key: 'in_days',
        values: [],
        converter: new DateChartConverter(),
      }

      let pregnancyWeightDateList = this.pregnancyWeightDateList;
      for (let dateNode of pregnancyWeightDateList) {
        let val = ExactNumber.toFloat(dateNode.value.weight);

        serial.values.push({
          key: dateNode.date,
          val,
          valLabel: `${dateNode.value.weightStr} kg${dateNode.value.levelStr ? ' ' + dateNode.value.levelStr : ''}`,
        })

      }

      this.chartSerials = [serial];
    },

    updateChartSerialModeInWeeks: function () {
      let serial = {
        color: '#f091a6',
        key: 'in_weeks',
        values: [],
        converter: new GravidaWeekChartConverter(0),
      }

      let pregnancyWeightWeekList = this.pregnancyWeightWeekList;
      for (let weekNode of pregnancyWeightWeekList) {
        let val = ExactNumber.toFloat(weekNode.value.weight);
        serial.values.push({
          key: `${serial.converter.PFEFIX}${weekNode.week}`,
          val,
          valLabel: `${weekNode.value.weightStr} kg${weekNode.value.levelStr ? ' ' + weekNode.value.levelStr : ''}`
        });
      }

      this.chartSerials = [serial];
    },

    updateStandardRegion: function () {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.updateStandardRegionModeInDays();
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.updateStandardRegionModeInWeeks();
          break;
      }
    },

    updateStandardRegionModeInDays: function () {
      let standardRegion = {
        color: '#faf4f5',
        values: [],
      }

      let standardList = this.standardList;
      for (let weekNode of standardList) {
        standardRegion.values.push({
          key: weekNode.range.end,
          lower: ExactNumber.toFloat(weekNode.value.lowerLimit),
          upper: ExactNumber.toFloat(weekNode.value.upperLimit),
        });
      }

      this.chartStandardRegions = [standardRegion];
    },

    updateStandardRegionModeInWeeks: function () {
      let standardRegion = {
        color: '#faf4f5',
        values: [],
      };

      let converter = new GravidaWeekChartConverter();

      let standardList = this.standardList;
      for (let weekNode of standardList) {
        standardRegion.values.push({
          key: converter.PFEFIX + weekNode.week,
          lower: ExactNumber.toFloat(weekNode.value.lowerLimit),
          upper: ExactNumber.toFloat(weekNode.value.upperLimit),
        });
      }

      this.chartStandardRegions = [standardRegion];
    },

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    postGravidaWeightLoaded: function (gravidaWeight) {
      gravidaWeight.weightStr = ExactNumber.stringify(gravidaWeight.weight);
      gravidaWeight.overWeightStr = ExactNumber.stringify(gravidaWeight.overWeight);
      gravidaWeight.underWeightStr = ExactNumber.stringify(gravidaWeight.underWeight);
      let levelStr = null;
      switch (gravidaWeight.level) {
        case GravidaWeightLevel.NORMAL:
          levelStr = "正常";
          break;
        case GravidaWeightLevel.OVER_WEIGHT:
          levelStr = "偏重";
          break;
        case GravidaWeightLevel.UNDER_WEIGHT:
          levelStr = "偏轻";
          break;
      }
      gravidaWeight.levelStr = levelStr;
    },

    loadGravidaWeightListBefore: function () {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.loadGravidaWeightDateListBefore();
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.loadGravidaWeightWeekListBefore();
          break;
      }
    },

    loadGravidaWeightListAfter: function () {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.loadGravidaWeightDateListAfter();
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.loadGravidaWeightWeekListAfter();
          break;
      }
    },

    loadGravidaWeightListAround: function (date) {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.loadGravidaWeightDateListAround(date);
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.loadGravidaWeightWeekListAround(date);
          break;
      }
    },

    loadGravidaWeightNode: function (date) {
      switch (this.mode) {
        case GravidaWeightChartMode.MODE_IN_DAYS:
          this.loadGravidaWeightDateNode(date);
          break;
        case GravidaWeightChartMode.MODE_IN_GRAVIDA_WEEK:
          this.loadGravidaWeightWeekNode(date);
          break;
      }
    },

    loadGravidaWeightDateListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.pregnancyWeightDateList.length > 0 ? this.pregnancyWeightDateList[0].date : null;
      this.$reqGet({
        path: '/mt/gravida/weight/date/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            for (let dateNode of dateNodeList) {
              this.postGravidaWeightLoaded(dateNode.value);
            }
            let pregnancyWeightDateList = this.pregnancyWeightDateList;
            this.$appendByOrder(pregnancyWeightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightDateListAfter: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.pregnancyWeightDateList.length > 0 ? this.pregnancyWeightDateList[this.pregnancyWeightDateList.length - 1].date : null;
      this.$reqGet({
        path: '/mt/gravida/weight/date/list/get/after',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            for (let dateNode of dateNodeList) {
              this.postGravidaWeightLoaded(dateNode.value);
            }
            let pregnancyWeightDateList = this.pregnancyWeightDateList;
            this.$appendByOrder(pregnancyWeightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightDateListAround: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/gravida/weight/date/list/get/around',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            for (let dateNode of dateNodeList) {
              this.postGravidaWeightLoaded(dateNode.value);
            }
            let pregnancyWeightDateList = this.pregnancyWeightDateList;
            this.$appendByOrder(pregnancyWeightDateList, dateNodeList, "date", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightDateNode: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;

      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/gravida/weight/date/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNode = res.data;
            let pregnancyWeightDateList = this.pregnancyWeightDateList;
            if (dateNode) {
              this.postGravidaWeightLoaded(dateNode.value);
              this.$appendByOrder(pregnancyWeightDateList, [dateNode], "date", "ASC");
            } else {
              for (let n = pregnancyWeightDateList.length - 1; n >= 0; n--) {
                if (pregnancyWeightDateList[n].date === date) {
                  pregnancyWeightDateList.splice(n, 1);
                  break;
                }
              }
            }
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightWeekListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let week = this.pregnancyWeightWeekList.length > 0 ? this.pregnancyWeightWeekList[0].week : null;

      this.$reqGet({
        path: '/mt/gravida/weight/gravida_week/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          week,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weekNodeList = res.data;
            for (let weekNode of weekNodeList) {
              this.postGravidaWeightLoaded(weekNode.value);
            }
            let pregnancyWeightWeightList = this.pregnancyWeightWeekList;
            this.$appendByOrder(pregnancyWeightWeightList, weekNodeList, "week", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightWeekListAfter: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let week = this.pregnancyWeightWeekList.length > 0 ? this.pregnancyWeightWeekList[this.pregnancyWeightWeekList.length - 1].week : null;

      this.$reqGet({
        path: '/mt/gravida/weight/gravida_week/list/get/after',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          week,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weekNodeList = res.data;
            for (let weekNode of weekNodeList) {
              this.postGravidaWeightLoaded(weekNode.value);
            }
            let pregnancyWeightWeightList = this.pregnancyWeightWeekList;
            this.$appendByOrder(pregnancyWeightWeightList, weekNodeList, "week", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightWeekListAround: function (date) {
      if (!this.userId || !this.organizationid) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadignCode;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/gravida/weight/gravida_week/list/get/around/date',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weekNodeList = res.data;
            for (let weekNode of weekNodeList) {
              this.postGravidaWeightLoaded(weekNode.value);
            }
            let pregnancyWeightWeightList = this.pregnancyWeightWeekList;
            this.$appendByOrder(pregnancyWeightWeightList, weekNodeList, "week", "ASC");
            this.updateChartSerials();
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    },

    loadGravidaWeightWeekNode: function (date) {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;

      this.busyLoadingCode = this.loadingCode;

      date = TimeUtils.format('yyyy-MM-dd', date);
      this.$reqGet({
        path: '/mt/gravida/weight/gravida_week/get/by_date',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let weekNode = res.data;
            let pregnancyWeightWeekList = this.pregnancyWeightWeekList;
            if (weekNode) {
              this.postGravidaWeightLoaded(weekNode.value);
              this.$appendByOrder(pregnancyWeightWeekList, [weekNode], "week", "ASC");
            } else {
              for (let n = pregnancyWeightWeekList.length - 1; n > 0; n--) {
                let tmpWeekNode = pregnancyWeightWeekList[n];
                if (tmpWeekNode.range.start <= date && tmpWeekNode.range.end >= date) {
                  pregnancyWeightWeekList[n].splice(n, 1);
                  break;
                }
              }
            }
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },

    loadGravidaWeightStandardList: function () {
      if (!this.userId || !this.organizationId) return;

      this.$reqGet({
        path: '/mt/gravida/weight/standard/list/get',
        data: {
          userId: this.userId,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let standardList = res.data;
            this.standardList = standardList;
            this.updateStandardRegion();
          })
          .catch(() => {
            this.$message.error("加载失败");
          });
    }


  }
}
</script>

<style scoped>

</style>