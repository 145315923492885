<template>
  <ys-infinite-scroll-view
      @loadmore="loadPregnancyWeightDateNodeListBefore"
  >
    <gravida-weight-list-item
        v-for="dateNode in weightDateNodeList"
        :key="dateNode.date"
        :user-id="userId"
        :organization-id="organizationId"
        :value="dateNode"
    ></gravida-weight-list-item>
    <div class="font-align-center font-size-small font-color-placeholder padding-horizontal padding-bottom-large">已滑动到最后</div>
  </ys-infinite-scroll-view>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import ExactNumber from "@/assets/javascript/exact-number";
import GravidaWeightListItem from "@/pages/minitools/gravida_weight/components/GravidaWeightListItem";

export default {
  name: "GravidaWeightList",
  mixins: [httpapi],
  components: {GravidaWeightListItem, YsInfiniteScrollView},
  props: {
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 体重日列表
       */
      weightDateNodeList: [],
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.loadPregnancyWeightDateNodeListBefore();
      },
      immediate: true,
    }
  },
  methods: {

    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },

    postGravidaWeightLoaded(dateNode) {
      let gravidaWeight = dateNode.value;
      gravidaWeight.weightStr = ExactNumber.stringify(gravidaWeight.weight);
      gravidaWeight.overWeightStr = ExactNumber.stringify(gravidaWeight.overWeight);
      gravidaWeight.underWeightStr = ExactNumber.stringify(gravidaWeight.underWeight);
    },

    loadPregnancyWeightDateNodeListBefore: function () {
      if (!this.userId || !this.organizationId) return;
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;

      let date = this.weightDateNodeList.length > 0 ? this.weightDateNodeList[this.weightDateNodeList.length - 1].date : null;
      this.$reqGet({
        path: '/mt/gravida/weight/date/list/get/before',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateNodeList = res.data;
            for (let dateNode of dateNodeList) {
              this.postGravidaWeightLoaded(dateNode);
            }
            this.$appendByOrder(this.weightDateNodeList, dateNodeList, "date", "DESC");
          })
          .catch(() => {
            this.$message.error("加载失败");
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          });
    }
  }
}
</script>

<style scoped>

</style>