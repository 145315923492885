<template>
  <div class="weight-list-item">
    <div class="date-weight-record layout-horizontal layout-middle" @click="handleClickDateRecord">
      <time-line
          :title="value.date"
          class="layout-self-stretch"
      ></time-line>
      <div class="font-size-small margin-left">日平均：</div>
      <div class="padding-left padding-vertical-large font-size-medium">{{ value.value.weightStr }} kg</div>
      <div class="margin-left">
        <span class="font-color-success" v-if="value.value.level === GravidaWeightLevel.NORMAL">正常</span>
        <span class="font-color-danger" v-else-if="value.value.level === GravidaWeightLevel.OVER_WEIGHT">偏重<span
            v-if="value.value.overWeightStr">{{ value.value.overWeightStr }}</span></span>
        <span class="font-color-danger" v-else-if="value.value.level === GravidaWeightLevel.UNDER_WEIGHT">偏轻<span
            v-if="value.value.underWeightStr">{{ value.value.underWeightStr }}</span></span>
      </div>
      <div class="layout-flexible"></div>
      <div class="font-color-placeholder padding-right-large">
        <span v-if="isExpanded"><i class="fas fa-angle-up"></i></span>
        <span v-else><i class="fas fa-angle-down"></i></span>
      </div>
    </div>
    <el-collapse-transition>
      <div v-if="isExpanded && weightTimeNodeList.length > 0">
        <div
            class="time-weight-record layout-horizontal layout-middle"
            v-for="timeNode in weightTimeNodeList" :key="timeNode.time"
        >
          <time-line
              :title="timeNode.timeStr"
              color="#ffc4cd"
              class="layout-self-stretch"
          ></time-line>
          <div class="padding-left padding-vertical-large font-size-medium">{{ timeNode.value.weightStr }} kg</div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import TimeLine from "@/pages/minitools/components/TimeLine";
import {TimeUtils} from "@/assets/javascript/time-utils";
import ExactNumber from "@/assets/javascript/exact-number";
import {GravidaWeightLevel} from "@/assets/javascript/gravida-weight-utils";

export default {
  name: "GravidaWeightListItem",
  components: {TimeLine},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
    value: Object,
  },
  data() {
    return {
      /**
       * 常量
       */
      GravidaWeightLevel: GravidaWeightLevel,

      /**
       * 是否展开
       */
      isExpanded: false,

      /**
       * 体重时间节点列表
       */
      weightTimeNodeList: [],

    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.isExpanded = false;
        this.weightTimeNodeList = [];
      },
      immediate: true,
    },
    isExpanded: {
      handler() {
        if (this.isExpanded) {
          this.loadGravidaWeightDateRecord();
        }
      },
      immediate: true,
    }
  },
  methods: {

    handleClickDateRecord: function () {
      this.isExpanded = !this.isExpanded;
    },

    loadGravidaWeightDateRecord: function () {
      let date = this.value.date;
      this.$reqGet({
        path: '/mt/gravida/weight/date_record/get',
        data: {
          userId: this.userId,
          organizationId: this.organizationId,
          date,
        }
      })
          .returnWhenCodeMatches()
          .then(res => {
            let dateRecord = res.data.value;
            let weightTimeNodeList = dateRecord.weightList;
            let reversedTimeNodeList = new Array(weightTimeNodeList.length);
            for (let n = 0; n < weightTimeNodeList.length; n++) {
              let timeNode = weightTimeNodeList[n];
              timeNode.timeStr = TimeUtils.format('HH:mm', '2021-11-13 ' + timeNode.time);
              timeNode.value.weightStr = ExactNumber.stringify(timeNode.value.value);
              reversedTimeNodeList[weightTimeNodeList.length - n - 1] = timeNode;
            }
            this.weightTimeNodeList = reversedTimeNodeList;
          })
          .catch(() => {
            this.$message.error("加载失败");
          });
    }
  }
}
</script>

<style scoped>

.date-weight-record:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}


</style>